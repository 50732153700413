<script>
import moment from "moment";

export default {
  props: {
    animationDuration: {
      type: Number,
      default: 1500,
    },
    duration: {
      type: Number,
      default: 4000,
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      expiryDate: null,
      intervalId: null,
      mouse: false,
      remainDuration: this.duration,
      show: true,
      timeoutId: null,
      timerProgress: 100,
    };
  },
  computed: {
    computedClass() {
      return `notification ${this.show ? "show" : "hide"}`;
    },
    computedStyle() {
      return `--animation-duration: ${this.animationDuration}ms`;
    },
    timerInterval() {
      return Math.ceil(this.duration / 400);
    },
  },
  methods: {
    startTimer() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        clearInterval(this.intervalId);
      }

      this.expiryDate = moment().add(this.remainDuration, 'milliseconds');
      this.timeoutId = setTimeout(this.close, this.remainDuration);
      this.intervalId = setInterval(() => this.timerProgress -= 0.25, this.timerInterval);
    },
    pauseTimer() {
      if (!this.timeoutId) {
        return;
      }

      this.remainDuration = this.expiryDate.diff(moment(), 'milliseconds');
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    clearTimer() {
      if (!this.timeoutId) {
        return;
      }

      this.remainDuration = this.duration;
      this.timerProgress = 100;
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    close() {
      this.show = false;
      this.clearTimer();
      setTimeout(() => this.$emit("close"), this.animationDuration);
    },
    dismiss() {
      this.close();
      this.$emit("dismiss");
    },
  },
  mounted() {
    setTimeout(this.startTimer, this.animationDuration);
  },
}
</script>

<template>
  <div :class="computedClass" :style="computedStyle" @mouseenter="pauseTimer" @mouseleave="startTimer">
    <div class="message" v-html="message" />
    <v-btn class="close" :ripple="false" icon x-small @click="dismiss">
      <v-icon color="#ffffff">mdi-close</v-icon>
    </v-btn>
    <div class="progress" :style="{ width: `${timerProgress}%` }" />
  </div>
</template>

<style lang="scss" scoped>
.notification {
  background-color: #44A4FC;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  margin: 0.3125rem 0.3125rem 0;
  max-width: 500px;
  min-width: 200px;
  padding: 0.625rem;
  position: relative;
  width: fit-content;

  &.show {
    @keyframes slide-in {
      0% {
        opacity: 0;
        transform: translateX(+102%);
      }

      100% {
        opacity: 1;
        transform: translateX(0%);
      }
    }

    animation-name: slide-in;
    animation-duration: var(--animation-duration);
  }

  &.hide {
    @keyframes slide-out {
      0% {
        opacity: 1;
        transform: translateX(0%);
      }

      100% {
        opacity: 0;
        transform: translateX(+102%);
      }
    }

    animation-name: slide-out;
    animation-duration: var(--animation-duration);
  }

  .close {
    width: min-content;

    .v-icon::before {
      text-shadow: none;
      opacity: 1;
    }
  }

  .progress {
    background-color: #327cc0;
    position: absolute;
    bottom: 0;
    right: 0;
    height: .25rem;
    width: 100%;
  }
}
</style>
